@use 'variable';

.main-form {
  font-size: $fs-2;
  line-height: 2.4rem;
  text-align: left;

  .form-title {
    @include style-text($font-weight-bold, $fs-3, 2.7rem, #2f67ba);
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    text-transform: lowercase;
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  // .ant-radio {
  //   top: 0.4em;
  // }

  .form-note {
    font-size: 1.2rem;
    color: #484e5480;

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 1.4rem;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .ant-form-item {
    margin-bottom: 1.6rem;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .main-title {
    text-align: center;
    color: #0a4874;
    font-size: 4.8rem;
    font-weight: 500;
    margin-bottom: 4.8rem;
  }

  .content-form {
    width: 41rem;
    margin: 0 auto;
  }

  .ant-input-number-input {
    padding: 0;
    height: auto;
  }

  .ant-input-number {
    width: 100%;

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .ant-picker-large,
  .ant-input-number,
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-picker {
    font-size: 1.4rem;
    border-radius: 6px;
    padding: 0.9rem 1.6rem;
    color: $color--ant-input;
    background-color: $color--background;
  }

  .ant-form-item-control-input-content {
    .ant-picker {
      width: 100%;
    }
  }

  .ant-picker-large {
    width: 100%;
  }

  .ant-form-item-label > label {
    color: #1d519e;
    font-weight: $font-weight-bold;
    font-size: $fs-2;
  }

  .ant-input-affix-wrapper .ant-input-password-icon {
    color: $color--header-text;
    height: 16px;
    width: 21.688297271728516px;
  }

  .remember__checkbox {
    color: $color--ant-checkbox;
    margin-bottom: 1.6rem;
  }

  .ant-input-disabled {
    background: #e9e9e9;
  }

  .reCaptcha__box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.2rem 0;
  }

  .error-status {
    color: $color--status-error;
    font-size: $fs-2;
    font-weight: $font-weight-normal;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    p {
      margin-bottom: 0;
    }

    span {
      margin-right: 1rem;
    }
  }


  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #c8c8c8;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: white;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 4.4rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 4.1rem;
  }

  .ant-select-arrow {
    color: #2f67ba;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      content: none;
      line-height: 0;
    }
  }
}

label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::after {
    display: inline-block;
    margin-right: 0.4rem;
    color: #ff4d4f;
    font-size: 1.4rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.ant-form-item-explain.ant-form-item-explain-error div {
  color: #ff4d4f;
  text-transform: lowercase;
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.ant-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

a.ant-typography.view-document-detail {
  color: #1d519e;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  display: block;
  text-decoration: underline;
  width: 100%;
}
