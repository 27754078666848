@use "variable";
@use "mixin";

.radio-margin {
  width: 100%;

  label {
    width: 100%;
    display: flex;

    &>span:not(.ant-radio) {
      width: 100%;
    }
  }

  .ant-col.ant-form-item-control {
    margin-left: -2rem;
  }

  .ant-row.ant-form-item {
    width: calc(100% + 2.5rem);
  }
}

.radio-channel-detail {
  margin-left: 1rem;

  .information-radio-channel {
    min-height: 70rem;
    min-width: 20rem;
    border-radius: 0.8rem;
    overflow: hidden;
    margin-right: 3.2rem;
    background-color: #FFFFFF;

    .label-title-detail-left {
      padding: 1.2rem 0 1.2rem 1.5rem;
      @include style-text($font-weight-medium, $fs-1, 2.1rem, #2F67BA);
      background: #E1EDFF;

    }

    .label-title {
      @include style-text($font-weight-medium, $fs-2, 2.4rem, #0A4874);
      margin-bottom: .8rem;

      &::after {
        content: ":";
      }
    }

    .label-content {
      @include style-text($font-weight-normal, $fs-2, 2.4rem, #03031D);
      margin-bottom: 2.4rem;

    }
  }

  .tab-content {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-radio-channel {
    .checkbox-group-status-use {
      .ant-checkbox-group {
        height: 4.5rem;
        display: flex;
        align-items: center;
      }
    }

  }
}