@import './variable';
@import './mixin';

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: normal;
  font-display: swap;
  src: local('Helvetica Neue Regular'), local('helvetica-neue-regular'),
    url('../../shared/assets/font/Helvetica/helvetica-neue-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: bold;
  font-display: swap;
  src: local('Helvetica Neue Bold'), local('helveticaneuebold'),
    url('../../shared/assets/font/Helvetica/helveticaneuebold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-display: swap;
  src: local('Helvetica Neue Medium'), local('helveticaneuemedium'),
    url('../../shared/assets/font/Helvetica/helveticaneuemedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-display: swap;
  src: local('Helvetica Neue Light'), local('helveticaneuelight'),
    url('../../shared/assets/font/Helvetica/helveticaneuelight.ttf') format('truetype');
}

html {
  font-size: 5.5px;
}

body {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  font-weight: normal;
  color: $color--main-text;
  background-color: $color--background;
  font-family: $main-font, 'Segoe UI', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', serif, serif;
}

a,
a:hover {
  text-decoration: none;
  color: $color--main-text;
  transition: 0.2s;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-select {
  margin-right: 1vw;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

@include respond(xl-screen) {
  html {
    font-size: calc(100vw * 10 / 1920);
  }
}

.tox-notifications-container {
  display: none;
}
img {
  object-fit: contain;
}
div.fileName {
  display: flex;
  flex-direction: row;
  align-items: center;

  .anticon {
    color: rgba(0, 0, 0, 0.85);
  }

  a.a-eclipse {
    max-width: 35rem;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.col-text-center {
  display: flex;
  align-items: center;
}
.text-center-col {
  margin-top: 1.5rem;
  display: block;
  width: 100%;
  text-align: center;
}
.ant-table-cell-scrollbar {
  display: none;
}
.custom-long-text {
  white-space: nowrap;
  width: 50rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
}
.ant-select-selection-item > span,
.ant-select-item-option-content > span {
  display: inline-block;
}

.ant-select-selection-item > span:first-letter,
.ant-select-item-option-content > span:first-letter {
  text-transform: uppercase;
}
