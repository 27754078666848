@use "variable";

.circle-label {
  color: #03031D;
  display: flex;
  align-items: center;

  .circle-status {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 7px;
  }

  .circle-new {
    background-color: #18e306;
  }

  .circle-time-left {
    background-color: #347aff;
  }

  .circle-expired {
    background-color: #878890;
  }

  .circle-cancel {
    background-color: #ff4747;
  }

  .icon-disable {
    color: #878890;
    cursor: not-allowed;
  }
}