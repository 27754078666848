@use "variable";

.all-page-component {
  display: flex;
  height: 100%;
  background-color: $color-background-layout;
  flex-direction: row;
}

.logo-header {
  width: 22.4rem;
  object-fit: contain;
  height: fit-content;
  margin-top: 2.4rem;
  margin-left: 6.5rem;
}

.right-page-component {
  width: 100%;
  overflow-y: auto;
  z-index: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-component {
  padding: 0rem 5.6vw 0 1.6vw;
  flex: 1;
}

.ant-badge-status-text {
  font-size: 1.6rem;
}

.ant-badge-status-dot {
  width: 0.9rem;
  height: 0.9rem;
}

.ant-input-number,
.ant-tooltip,
.ant-select-item,
.ant-select,
.ant-table {
  font-size: 1.4rem;
  width: 100%;
}

.ant-select-item,
.ant-select {
  font-size: 1.4rem;
  width: 100%;
}

.ant-tooltip {
  position: fixed !important;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 4rem;
  line-height: 4rem;
  padding: 0 1.6rem;
}

.ant-picker {
  font-size: 1.4rem;
  padding: 0.4rem 1.1rem 0.4rem;
}

.ant-picker-input>input {
  font-size: 1.4rem;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel {
  width: 28rem;
}

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after,
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  width: 0.7rem;
  height: 0.7rem;
}

.ant-picker-date-panel .ant-picker-content {
  width: 25.2rem;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 0.8rem 1.2rem;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 3.6rem;
}

.ant-picker-dropdown {
  font-size: 1.4rem;
}

.ant-picker-header button {
  line-height: 4rem;
}

.ant-picker-header>button {
  font-size: 1.4rem;
}

.ant-picker-cell::before {
  height: 2.4rem;
}

.ant-picker-content th,
.ant-picker-content td {
  min-width: 2.4rem;
}

.ant-picker-cell .ant-picker-cell-inner {
  height: 2.4rem;
  line-height: 2.4rem;
  min-width: 2.4rem;
}

.ant-picker-range .ant-picker-clear {
  right: 1.1rem;
}

.select-label-component .select-custom .ant-select-selector .ant-select-selection-item,
.select-label-component .ant-select .ant-select-selector .ant-select-selection-item,
.select-label-component .ant-select-single .ant-select-selector .ant-select-selection-item,
.select-label-component .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  padding: 0.4rem 1rem;
  margin-right: 1rem;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  height: 2.4rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 3.2rem;
  padding: 0 1.1rem;
}

.ant-select-dropdown {
  padding: 0.4rem 0rem;
}

.ant-select-item {
  min-height: 3.2rem;
  padding: 0.5rem 1.2rem;
  line-height: 2.2rem;
}

span.ant-select-selection-item {
  padding: 0.4rem 1rem;
  margin-right: 1rem;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 1.1rem !important;
  width: initial;
  height: initial;
}

.ant-select-single.ant-select-show-arrow .ant- select-selection-search {
  right: 2.5rem;
  left: 1.1rem;
}

.ant-input-number-input {
  height: 3rem;
  padding: 0 1.1rem;
}

.ant-select-arrow {
  width: 1.2rem;
  height: 1.2rem;
  right: 1.1rem;
  margin-top: 0rem;
  font-size: 1.2rem;
  top: 50%;
  transform: translate(0px, -50%);
}

.ant-pagination-item {
  min-width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.8rem;
  line-height: 3rem;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  font-size: 1.2rem;
}

.ant-pagination {
  font-size: 1.4rem;
}

.ant-table-pagination.ant-pagination {
  margin: 1.6rem 0rem;
}

.ant-table-pagination {
  row-gap: 0.8rem;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  height: 3.2rem;
  min-width: 3.2rem;
  line-height: 3.2rem;
}

.ant-tag {
  font-size: 1.2rem;
  padding: 0 0.7rem;
  margin-right: 0.8rem;
}

.ant-modal-close-x {
  width: 4.4rem;
  height: 4.4rem;
  line-height: 4.4rem;
  font-size: 1.6rem;

  svg {
    width: 2.7rem;
    height: 2.7rem;
    font-size: 2.7rem;
  }
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0.8rem;
}

.ant-form-item {
  font-size: 1.4rem;
}

.ant-form-item-control-input {
  min-height: 3.2rem;
}

.ant-picker-time-panel-column {
  width: 5.8rem;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 0 0 1.4rem;
}

.ant-picker-time-panel {
  width: 20rem;
}

.ant-picker-time-panel .ant-picker-content {
  height: 22.4rem;
}

.ant-picker-footer {
  line-height: 3.8rem;
}

.ant-picker-ranges {
  padding: 0.4rem 1.2rem;
  line-height: 3.4rem;
}

.ant-picker-large .ant-picker-input>input {
  font-size: 1.4rem;
}

.ant-picker-ranges .ant-picker-ok {
  margin-left: 0.8rem;

  button {
    min-width: 4rem;
    min-height: 2.8rem;

    span {
      font-size: 1.4rem;
    }
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  right: 1.1rem;
  left: 1.1rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
}

.ant-list-grid .ant-col>.ant-list-item {
  margin-bottom: 1.6rem;
}

.ant-list-item {
  padding: 1.2rem 0;
}

.ant-form-item-explain,
.ant-form-item-extra,
.ant-list {
  font-size: 1.4rem;
}

.ant-checkbox-wrapper,
.ant-form label {
  font-size: 1.4rem;
}

.ant-checkbox+span {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.ant-picker-range-separator {
  svg {
    width: 1.6rem;
  }
}

.ant-picker-suffix {
  svg {
    width: 2rem;
  }
}

span.ant-tree-title {
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
}

.ant-picker.ant-picker-range:hover svg {
  width: 2rem;
}

.ant-tabs-tab {
  font-size: 1.4rem;
  padding: 1.2rem 0;
}