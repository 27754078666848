@use "variable";

.language-icon {
  width: 2rem;
  height: 2rem;
  margin-left: 5px;
  margin-bottom: 4px;
}

.select-custom {
  .icon-angel-down {
    font-size: $fs-4;
    color: $color--header-text;
    margin-top: -6px;
  }

  .ant-select,
  .ant-select-single,
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid $color--header-text;
      min-height: 4.6rem;

      &:hover {
        border-color: $color--header-text;
      }

      .ant-select-selection-item {
        padding: 0.6rem 0.5rem;
        margin-right: 1.4rem;
        font-weight: $font-weight-medium;
        color: $color--header-text;
      }
    }

    .ant-select-arrow {
      margin-right: 0.6rem;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
}

.ant-select:not(.ant-select-disabled):hover,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: $color--header-text;
    box-shadow: none;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

.change-language-box {
  .ant-select-selector {
    background-color: #ffffff !important;
  }

  .icon-angel-down {
    font-size: $fs-4;
    color: $color--header-text;
    margin-top: -6px;
  }

  .ant-select,
  .ant-select-single,
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid $color--header-text;
      min-height: 4.6rem;
      margin-right: 1rem;
      align-items: center;

      &:hover {
        border-color: $color--header-text;
      }

      .ant-select-selection-item {
        padding: 0.6rem 0.5rem;
        margin-right: 1.4rem;
        font-weight: $font-weight-medium;
        color: $color--header-text;
      }
    }

  }

  .ant-select-arrow {
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
  }
}