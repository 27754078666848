@use "variable";
@use "mixin";

.newsletter-page {
    .main-card {
        margin-right: -8rem;
        padding: 1.6rem 2.4rem;
    }


    .ant-radio-wrapper {
        span {
            @include style-text($font-weight-normal, $fs-2, 2.4rem, #03031D)
        }
    }

    .ant-radio-group {
        padding: 0.75rem 0;
        width: 100%
    }

    .newsletter-information {
        margin-bottom: 0.8rem;

        .ant-input-disabled {
            background: #EDEFF4;
            @include style-text($font-weight-normal, $fs-2, 2.4rem, #595959);
        }

        .ant-select-disabled {
            .ant-select-selector {
                background: #EDEFF4;
                @include style-text($font-weight-normal, $fs-2, 2.4rem, #595959);
            }


        }

        .schedule-playlistId__box {
            width: 77%;


            .schedule-playlistId__select {
                flex: 1;

                .ant-select-selection-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .a.ant-typography-ellipsis-single-line,
                    span.ant-typography-ellipsis-single-line {
                        flex: 1;
                    }

                    .infor-box {
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;

                        .item-duration {
                            margin: auto 1rem;
                        }
                    }


                    svg {
                        color: #2F67BA;
                    }
                }
            }

            .playlist-button-box {
                margin-top: 3.2rem;
                padding-left: 1rem;
                justify-content: space-between;

                .ant-btn.ant-btn-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1rem;
                    background: #FFFFFF;
                    border: 1px solid #2F67BA;
                    border-radius: 6px;
                    min-width: 4.7rem;
                    min-height: 4.7rem;
                    width: 4.7rem;
                    height: 4.7rem;
                }

            }

        }





        .ant-select-selection-overflow-item {
            .ant-select-selection-item {
                @include style-text($font-weight-normal, $fs-1, auto, #5490EB);
                padding: 2px;
                background: #E1EDFF;
                border-radius: 4px;
            }

            .ant-select-selection-item-remove {
                color: #5490EB;
                padding: 5px;
            }

        }

    }

    .newsletter-schedule {
        .display-none-Item {
            display: none;
        }

        .form-row-label {
            flex-direction: row;
            align-items: baseline;

            .ant-form-item-control {
                width: 8rem;
                margin-left: .8rem;
            }
        }

        .ant-picker.ant-picker-disabled {
            background: #EDEFF4;

        }

        .ant-picker-input>input[disabled] {
            color: #6C7585;

        }

        .ant-picker-suffix {
            color: #2F67BA
        }

        .hidden-placeholder {
            visibility: hidden;
        }

        .checkbox-day {
            .ant-form-item {
                margin-bottom: 0;
            }

            .ant-checkbox-group {
                margin-top: -5rem;
                padding-left: 2rem;
            }

            .ant-form-item-explain-error {
                padding-left: 1.3rem;

            }

            .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                margin-left: 0px;
            }

            .week-checkbox label.ant-checkbox-wrapper.ant-checkbox-group-item {
                width: 46%;
            }

            .checkbox-group-all {
                padding-left: 2rem;

            }
        }


    }

    .scheduleTimes-box .ant-btn-link {
        min-height: 2rem;
        text-align: left;
    }

    .button-newsletter {
        .cancel-button {
            margin-right: 3.2rem;
        }
    }

}

.schedule-playlistId__item {
    .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .span.ant-typography {
            flex: 1;
        }

        .infor-box {
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-right: 1rem;

            .item-duration {
                margin: auto 1rem;
            }
        }


        svg {
            color: #2F67BA;
        }
    }

}