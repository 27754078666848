@use "variable";
@use "mixin";

.add-survey-page {}

.form-survey {
  margin-bottom: 3.6rem;

  .question-list {
    max-height: 62rem;
    padding: 0 1rem;
    overflow-y: auto;

    .question-item-box {
      color: #2F67BA;

      .question-title {
        span {
          @include style-text($font-weight-medium, $fs-2, auto, #000000);
        }

        .question-blue {
          color: #2F67BA;
        }

        margin-bottom: 1rem;
      }

      .group-answer {
        width: 100%;
        padding-left: 3rem;

        p {
          @include style-text($font-weight-normal, $fs-1, auto, #000000);
        }

        .answer-item {
          margin-bottom: 1.6rem;

          label :nth-child(2) {
            @include style-text($font-weight-normal, $fs-1, auto, #000000);

          }
        }
      }
    }

  }
}

.answerList-box {
  max-height: 42vh;
  overflow: scroll;


}

.list-survey,
.feedback-comment {
  .ant-picker {
    font-size: 3.4rem;
    border-radius: 6px;

    .ant-picker-suffix {
      margin-left: 0rem;
      color: #2f67ba;

      svg {
        margin-top: 0.5rem;
      }
    }
  }
}

.result-survey-page {
  .survey-title {
    @include style-text($font-weight-bold, $fs-3, 2.7rem, #2f67ba);
  }

  .answer-list-box {
    max-height: 62rem;
    padding: 0 1rem;
    overflow-y: auto;

    .answer-item-box {
      width: 100%;

      .ant-list-header {
        border-bottom: none;
        padding-bottom: 0;

        .answer-header {
          @include style-text($font-weight-medium, $fs-2, auto, #03031d);
          margin-bottom: 0;

          span {
            color: #2f67ba;
          }
        }
      }

      .ant-list-items {
        margin-left: 5rem;

        .ant-list-item {
          @include style-text($font-weight-normal, $fs-2, auto, #03031d);
          border-bottom: none;
          padding: 0.8rem 0;
        }
      }
    }
  }
}