@use 'variable';
@use 'mixin';

.add-template {
  .display-none-button button.video-react-big-play-button.video-react-big-play-button-left {
    display: none;
  }

  .main-title-breadcrum {
    padding: 0 3.2rem 0 0.6rem;
  }

  .form-template {
    padding: 0 3.2rem 0 0.6rem;

    .ant-card-head-title {
      padding: 0;
    }

    .infinite-container {
      height: 53vh;
      overflow-y: scroll;
    }
    .text-display {
      background: #000;
      color: #fff;
      padding: 1rem 2rem;
      height: 100%;
    }
  }

  .ant-list {
    // height: 54vh;

    .ant-spin-nested-loading {
      padding: 0.8rem;
    }

    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-list-item {
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      width: 100%;
      [role='SourceBox'] {
        width: 100%;
        height: 10rem;
        border-radius: 8px;
      }
      .card-item {
        width: 100%;
        height: 100%;

        .card__image {
          width: 100%;
          height: 70%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .card__label {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30%;
          text-align: center;
          span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
          }
        }
      }
    }

    .ant-list-item.blue-border {
      border: 0.5rem solid #237bd3;
      border-radius: 0.8rem;
    }
  }

  .ant-picker-disabled,
  .ant-input-number-disabled,
  .ant-input-disabled {
    background-color: #d2d2d2;
  }

  .ant-picker-input > input[disabled] {
    color: #03031d;
  }
  .ant-card-body {
    padding: 1.6rem;
    padding-right: 0.8rem;
  }

  .title-infor {
    @include style-text($font-weight-medium, $fs-2, 2.4rem, #237bd3);
  }

  .list-template-right {
    .ant-list {
      overflow-y: scroll;
    }
    .text-display {
      background: #000;
      color: #fff;
      padding: 1rem 2rem;
      height: 100%;
      max-height: 100%;
    }
    padding: 1.6rem 1.6rem;

    .ant-spin-nested-loading {
      padding: 0.5rem;
    }

    .ant-list-item {
      // border: 0.5rem solid #237bd3;
      // border-radius: 0.8rem;

      img {
        border-radius: 0px;
      }
    }

    .ant-list .list-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      row-gap: 0.5rem;
      div {
        cursor: pointer;
        background-color: #cecececc;
        border-radius: 4px;
        padding: 0.4rem 0.8rem;
      }
      svg {
        color: #237bd3;
        width: 1.4rem;
        height: 1.4rem;
      }
      .icon-active {
        background-color: #237bd3;
        svg {
          color: white;
        }
      }
    }
  }

  .template-media {
    .ant-card-head {
      padding: 1.6rem 2rem;
    }

    .ant-input {
      width: 100%;
    }
  }

  .video-react {
    .video-react-poster {
      background-size: cover;
      background-position: top;
    }

    .video-react-big-play-button {
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .video-react:hover .video-react-big-play-button,
  .video-react .video-react-big-play-button:focus {
    background: none;
  }

  .button-center__box {
    margin-top: 6.4rem;

    .cancel-button {
      margin-right: 4rem;
    }
  }

  label,
  .ant-typography {
    color: #1d519e;
    font-weight: 700;
  }

  .template-media {
    border-radius: 12px 12px 0px 0px;
    .search-bar {
      .ant-input-affix-wrapper {
        width: 100%;
      }
    }
    .ant-card-head {
      background-color: #e1edff;
      border-radius: 12px 12px 0px 0px;

      .label-select {
        color: #1d519ecc;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }

  .right-panel {
    border-radius: 1.2rem;

    .main-view {
      border-radius: 1.2rem;
      border: 2px solid #5490eb;
      height: 60vh;
      width: 80vh;
      position: relative;
      overflow: hidden;
      max-width: 100%;

      & > span {
        position: relative;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
      }
    }
  }

  .ant-card-body {
    width: 100%;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #237bd3;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  cursor: move;
  background-color: #cecececc;
  .text-display {
    background: #000;
    color: #fff;
    padding: 1rem 2rem;
    height: 100%;
    width: 100%;
  }
}

.react-resizable {
  position: absolute;
}

.react-draggable-active {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-resizable-handle.react-draggable {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  right: 0;
  bottom: 0;
}

.dash-border {
  border: 2px dashed #237bd3;
  border-bottom: 2px dashed #237bd3 !important;
}

.no-border {
  border: none;
}
