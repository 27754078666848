@use "variable";
@use "mixin";

.profile-user__box {
    margin-bottom: 5.6rem;
    background: #ffffff;
    border-radius: 0.8rem;

    .ant-row {
        .profile-form__box {
            margin: 0;
        }
    }

    .profile-form__box {
        background: #ffffff;
        padding: 4rem 5.6rem 4.8rem 5.6rem;
    }

    .profile-avatar {
        margin-right: 16.8rem;

        .avatar__box {
            width: 26.5rem;
            height: 26.5rem;
            border-radius: 50%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                margin-bottom: 2.4rem;

            }

            .button-icon-upload {
                width: 4.5rem;
                height: 4.5rem;
                border-radius: 50%;
                opacity: 0.85;
                background: #1d519e;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 3.9rem;
                bottom: 0;

                label {
                    color: white;
                    font-size: $fs-5;
                    line-height: 0px;
                    margin: 0;
                }
            }



            .account-name {
                text-align: center;

                p {
                    font-size: $fs-5;
                    color: #000000;
                    font-weight: $font-weight-medium;
                    line-height: 2.9rem;

                }
            }
        }
    }
}

.profile-button-update {

    button.ant-btn.cancel-button,
    button.ant-btn.normal-button {
        width: 162px;
        height: 44px;
        font-size: 16px;
        line-height: 24px;

    }

    .cancel-button {
        margin-right: 24px;
    }
}