.upload-item {
    button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        svg {
            color: red;
        }
    }
}