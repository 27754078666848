@use "variable";

.main-title {
  font-weight: bold;
  font-size: $fs-4;
  /* Blue 7 */
  color: $color--main-title;
  margin: 0;
  line-height: 3rem;
}

.secondary-title {
  font-size: $fs-5;
  color: white;
  font-weight: $font-weight-bold;
  margin-top: 1rem;
}