@use "variable";

.modal-delete-confirm {
    .ant-modal-confirm-btns .ant-btn {
        background-color: white;
        color: #237bd3;
    }

    .ant-modal-confirm-btns .ant-btn-primary {
        background-color: #1D519E;
        color: white;
        border-color: #1D519E;
    }

    .ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,
    .ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
        color: white;
    }



    .ant-modal-content {
        border-radius: 1.6rem;
        overflow: hidden;

        .ant-modal-body {
            padding: 0;

            .ant-modal-confirm-body>.anticon {
                display: block;
                font-size: 9.4rem;
                background: #ffb119;

                svg {
                    color: white;
                }

                width: 100%;
                margin-right: 0;
                padding: 4rem 0;
            }

            .ant-modal-confirm-body {
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;

                .ant-modal-confirm-title {
                    margin-top: 2.4rem;
                    background-color: transparent;
                    text-align: center;
                    font-weight: $font-weight-bold;
                    font-size: 2.4rem;
                    text-transform: unset;
                    color: #0A4874;
                    width: 40rem;
                }
            }

            .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
                margin: 0 auto;
                margin-top: 1.2rem;
                text-align: center;
                font-size: 1.6rem;
                color: #03031D;
                width: 40rem;
            }

            .ant-modal-confirm-btns {
                width: 100%;
                justify-content: center;
                display: flex;
                margin: 3.4rem 0;

                button:first-child {
                    margin-right: 2.4rem;
                }
            }
        }
    }
}