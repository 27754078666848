@use "variable";

.page-error {
    // position: relative;

    width: 100%;
    height: 100vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .label-logo-forgot {
        position: absolute;
        left: 50%;
        top: 4rem;
        transform: translateX(-50%);
        width: 22.4rem;
    }

    .main-content {
        position: absolute;
        width: 75rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        left: 8%;

        .title-404 {
            font-weight: bold;
            font-size: 7.2rem;
        }

        .page-not-found {
            font-size: $fs-5;
            line-height: 2.9rem;
            color: #03031d;
            opacity: 0.8;
        }

        .note-404 {
            margin-top: 2.4rem;
            font-size: $fs-2;
            line-height: 2.4rem;
            color: #03031d;
            opacity: 0.6;
        }

        .note-authorization {
            width: 57%;
            margin: 2.4rem auto;
        }

        .page-title-authorization {
            font-style: normal;
            font-weight: $font-weight-bold;
            font-size: 4.8rem;
            line-height: 3.6rem;
            color: #EC3740;

        }
    }
}