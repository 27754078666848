// @import "../../../assets/scss/variables";
.vertical-centered-box {
  // background: $color-theme;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 99999999 !important;
}

.content-wrapper-loading {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;

  .loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(150,150,150,0.3);
    margin-left: -60px;
    margin-top: -60px;
  }

  .loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    -webkit-transform-origin: 60px 60px;
    -moz-transform-origin: 60px 60px;
    -ms-transform-origin: 60px 60px;
    -o-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
    -webkit-animation: rotate 1.2s infinite linear;
    -moz-animation: rotate 1.2s infinite linear;
    -o-animation: rotate 1.2s infinite linear;
    animation: rotate 1.2s infinite linear;
  }

  .loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(150,150,150,0.8);
  }

  .icon-loading-font {
    position: absolute;
    font-size: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:before {
      color: #fafbfc !important;
    }
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}