@use "variable";
@use "mixin";

//===============Checkbox==================
.ant-checkbox-inner {
  width: 2.2rem;
  height: 2.2rem;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $color__main;
  @include no-box-shadow();

  &::after {
    left: 26%;
    width: 7px;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $color__main;
    border: none;
    border-width: 1px;
    border-radius: 5px;
  }

  &:hover {
    .ant-checkbox-checked::after {
      border: none;
    }
  }

  &::after {
    border: none;
  }
}

.ant-checkbox-wrapper {
  align-items: center;
  color: $color--ant-checkbox;

  .ant-checkbox {
    top: 0;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $color__main;
  color: white;
}


.ant-checkbox-inner::after {
  top: 46%;
  height: 1.2rem;
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    border-color: $color__main;
    border-width: 1px;
    border-radius: 5px;
  }

  .ant-checkbox-inner::after {
    background-color: $color__main;
  }
}


//====================RADIO===================
.ant-radio-checked {
  .ant-radio-inner {
    border-color: $color__main;

    &::after {
      transform: scale(0.6);
    }
  }
}

.ant-radio-inner {

  border-color: $color__main;

  &::after {

    background-color: $color__main;
  }
}