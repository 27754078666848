@use "variable";
@use "mixin";

.main-card {
  background: #ffffff;
  padding: 1.6rem 2.4rem;
  border-radius: 1.2rem;

  h4,
  h3.card-title {
    @include style-text($font-weight-medium, $fs-4, 3rem, $color--main-title);
    margin-bottom: 1.6rem;
    max-width: 90%;
  }

  .ant-picker-range {
    font-size: 3.1rem;
    border-radius: .6rem;
  }

}