@use "variable";
@use "mixin";
@import "./header.scss";

$size-img: 10rem;
$padding-inside: 1rem;


@mixin logo {
  width: 70%;
  min-width: $size-img;
}


.sider-component {
  display: flex;
  width: 4rem;
  align-content: center;
  align-items: center;
  transition: width 0.2s linear;
  overflow: hidden;
  cursor: pointer;
  background-color: $color-sidebar;
  border-radius: 16px;

  &>.w-100 {
    background-color: $color-sidebar;
    height: 100vh;
    border-radius: 16px;
    position: fixed;
    top: 0;
  }

  // &:hover{
  //   width: 14vw;
  // }

  .logo {
    text-align: center;

    img {
      margin: 4rem auto 8rem auto;
      @include logo();
    }
  }

  .icon {
    z-index: 1;
    top: 0;
    left: 0;
    width: 4rem;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    svg {
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  .mask {
    overflow: hidden;
    left: 0%;
    height: 100%;
    background-color: $color-sidebar;
    transition: opacity 0.2s linear;
    opacity: 0;
    z-index: 3;

    // &:hover{
    //   opacity: 1;
    // }

    .menu-hr {
      border-bottom: 1px solid #237bd387;
      margin: 1.2rem $padding-inside;
    }

    .menu-title {
      font-weight: bold;
      font-size: $fs-3;
      line-height: 2.7rem;
      text-transform: uppercase;
      color: $color--header-text;
      margin: 2rem $padding-inside;
    }

    .menu {
      .item-label {
        padding: 0.8rem 0rem;
        display: flex;
        flex-direction: row;
        position: relative;
        transition: 0.2s;
        align-items: center;
        margin: 0rem $padding-inside;
        justify-content: space-between;


        &>span:first-child {
          display: flex;
          align-items: center;
        }

        &:hover {
          opacity: 0.7;
        }

        .item__nav {
          @include text-wrap;
          font-weight: $font-weight-medium;
          font-size: 1.6rem;
          color: $color-sidebar-text;
          //width: 14.5rem;
          height: 100%;
          max-width: 8vw;
        }

        .item-hover__icon {
          margin-right: 1rem;

          svg {
            font-size: 3rem;
            width: 2.6rem;
            height: 2.6rem;
            color: $color-sidebar-text;
          }
        }

        .icon-3dot svg {
          color: $color-sidebar-text;
          font-size: 2.2rem;
          width: 2.2rem;
          height: 2.2rem;
        }
      }

      .menu--component--item.menu-active {
        position: relative;
        background-color: #2F67BACC;

        .item__nav {
          color: white;
        }

        .item-hover__icon svg {
          color: #fff;
        }

        .icon-3dot svg {
          color: #fff;
        }

        .icon-3dot {
          color: white;
        }
      }

      .menu--component--item.three-dot {
        cursor: pointer !important;
        position: relative;

        .icon-3dot {
          font-size: 3rem;
          margin-left: 1.2rem;
          margin-top: -7px;
          // line-height: 0;
        }

        // .dropdown-3dot {
        //   display: none;
        //   position: absolute;
        //   top: 0;
        //   background-color: white;
        //   z-index: 99;
        //   left: 100%;
        //   border-radius: 0.8rem;
        //   overflow-x: hidden;
        //   width: 26.5rem;

        //   &__item {
        //     display: block;
        //     text-align: left;
        //     transition: 0.2s;
        //     color: #6c7585;
        //     font-size: 1.6rem;
        //     padding: 1.1rem;

        //     &:hover {
        //       opacity: 0.7;
        //     }
        //   }

        //   .active {
        //     color: #1D519E;
        //     background-color: #E1EDFF;
        //     font-weight:500
        //   }
        // }

        // &:hover .dropdown-3dot {
        //   display: block;
        // }
      }
    }
  }
}

.big {
  width: 14vw;
  cursor: initial;

  .mask {
    opacity: 1;
  }
}


.sider-component-pointer {
  cursor: pointer;
}

.ant-menu {
  border-radius: 0.4rem;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  left: 110% !important;
  position: absolute;
  top: -1rem;
  transform: translate(0px, -50%);

  //width: 26.5rem;
  .ant-menu-item {
    font-size: 1.6rem;
    margin: 0;
    background-color: white;
    color: #6C7585;
  }

  .ant-menu-item-selected {
    font-weight: 500;
    color: #1D519E;
    background-color: #E1EDFF;
  }
}