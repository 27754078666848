.main-list {
  .padding-here {
    padding: 1.5rem 3rem;
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 4rem;
    margin-bottom: .8rem;
    margin-right: 2rem;
  }

  .ant-pagination-item,
  .ant-pagination-item a {
    background-color: transparent;
    border: none;
    color: #0A4874;
  }

  .ant-pagination-item a {
    padding: 0;
  }

  .ant-pagination-item {
    min-width: 2rem;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #0A4874;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: none;
    color: #0A4874;
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: $color__main;
  }

  .ant-pagination-item {
    font-family: $main-font;
  }

  .ant-pagination-item.ant-pagination-item-active,
  .ant-pagination-item.ant-pagination-item-active a,
  .ant-pagination-item.ant-pagination-item-active a:not([href]):not([tabindex]):focus,
  .ant-pagination-item.ant-pagination-item-active a:not([href]):not([tabindex]):hover {
    font-weight: 600;
    font-size: 1.4rem;
  }

  .ant-pagination-item.ant-pagination-item-active a {
    width: 2.6rem;
    background: #237BD3;
    border-radius: 4px;
    color: #ffffff;
  }
}