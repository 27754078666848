@use "variable";
$border-radius: 1.6rem;

.main-modal {

  .ant-modal-content,
  .ant-modal-header {
    border-radius: $border-radius;
  }

  .ant-modal-body {
    padding: 1rem 4rem;
  }

  .ant-modal-header {
    border: none;
    padding: 0 4rem 0;
    height: 8rem;
    display: flex;
    align-content: center;
    vertical-align: middle;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: $fs-5;
    line-height: 3.4rem;
    color: #0a4874;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .ant-typography{
      color: inherit;
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding: 3rem 0;
    text-align: center;
  }
}