@use "variable";
@use "mixin";

.ant-tree-treenode-motion {
    display: none;
}


.facility-management {
    height: 100%;

    .max-table {
        max-height: 50vh;
        overflow-y: scroll;
    }

    .facility-content__box {
        height: 100%;
        display: flex;
        flex-direction: row;
        max-height: 73vh;
        justify-content: space-between;
        overflow-y: hidden;
    }


    .main-view {
        width: 80%;
        height: 100%;
        max-height: 73vh;
        overflow-y: hidden;



        .card-main-table {
            max-height: calc(73vh - 8.1rem - 3.3rem - 1.7rem - 1.7rem - 1.7rem - 4.2rem);
            overflow-y: scroll;
            position: relative;

            .ant-spin-container {
                align-items: flex-end;
            }

            .main-table .ant-table {
                // padding-bottom: 4rem;
            }

            .main-table {


                .ant-table-pagination.ant-pagination {
                    margin-bottom: -2rem;
                    width: -moz-fit-content;
                    width: fit-content;
                }


            }

            .table-function {
                margin-top: -1.3rem;
            }

            .ant-input-number {
                position: static;
            }
        }

    }

    .location-tree-box {
        width: 18%;
        border-radius: 0.8rem;
        margin-right: 3.2rem;
        max-width: 30rem;

        .dots-icon {
            cursor: pointer;
        }

        .ant-tree {
            height: 100%;
            overflow: scroll;

            .ant-tree-list {
                height: 100%;

                .ant-tree-list-holder {
                    height: 100%;

                    &>div {
                        height: 100%;
                    }
                }

                .ant-tree-list-holder-inner {
                    width: 100%;
                    align-items: center;

                    &>div {
                        padding: 1.2rem 0;
                        width: 95%;
                        // border-left: 1px solid #B6D3FF;
                        // border-right: 1px solid #B6D3FF;

                        .ant-tree-icon__customize {
                            padding-right: 3rem;
                        }
                    }

                    .tree-ui-node {
                        .ant-tree-node-content-wrapper {
                            display: flex;
                            flex-direction: row-reverse;
                            width: 100%;
                            justify-content: space-between;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 1.8rem;
                        }

                        svg {
                            color: #2F67BA;
                        }

                        .ant-tree-indent {
                            display: none;
                        }
                    }

                    .tree-level-0 {
                        width: 100% !important;
                        margin: 0;
                        margin-bottom: 1.6rem !important;
                        background-color: #237BD3 !important;
                        border-radius: 4px 4px 0px 0px;
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        left: 0;
                        z-index: 2;

                        svg {
                            color: #ffffff;
                        }

                        .ant-tree-title {
                            @include style-text($font-weight-medium, $fs-1, auto, #ffffff);
                        }

                        .ant-tree-node-content-wrapper.ant-tree-node-selected {
                            background: none;
                            color: white
                        }

                    }

                    .tree-level-1 {
                        margin-bottom: 1.2rem;
                        border: 1px solid #B6D3FF;
                        border-radius: 6px;

                        .ant-tree-title {
                            @include style-text($font-weight-medium, $fs-0, auto, #2F67BA);
                        }
                    }

                    .tree-level-2,
                    .tree-level-3,
                    .tree-level-4,
                    .tree-level-5,
                    .tree-level-6 {
                        .ant-tree-title {
                            @include style-text($font-weight-normal, $fs-0, auto, #03031D);

                        }

                        .ant-tree-node-selected {
                            .ant-tree-title {
                                color: #2F67BA;
                            }
                        }

                    }

                    .tree-level-2.ant-tree-treenode-switcher-open {
                        border-radius: 0;
                    }


                    .tree-level-2 {
                        padding-left: 2rem;

                    }

                    .tree-level-3 {
                        padding-left: 4rem;

                    }

                    .tree-level-4 {
                        padding-left: 6rem;

                    }

                    .tree-level-5 {
                        padding-left: 8rem;

                    }

                    .tree-level-6 {
                        padding-left: 10rem;

                    }



                    .tree-level-2.ant-tree-treenode-leaf-last.treenode-switcher-open {
                        border-bottom: 1px solid #B6D3FF;
                        border-radius: 0 0 6px 6px;
                        margin-bottom: 1.6rem;

                    }

                    // .tree-level-3.ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
                    //     border-bottom: 1px solid #B6D3FF;
                    //     border-radius: 0 0 6px 6px;
                    //     margin-bottom: 1.6rem;
                    // }

                    .ant-tree-switcher {
                        color: #2F67BA;
                        display: flex;
                        align-items: center;
                        width: 2rem;
                        margin-left: 2rem;

                        .ant-tree-switcher-icon {
                            font-size: 1.2rem;
                        }
                    }

                    .ant-tree-treenode-switcher-open {
                        margin-bottom: 0;
                        border-bottom: none;
                        border-radius: 6px 6px 0 0;
                    }

                    // .not-children.tree-level-2 {
                    //     margin-bottom: 0rem;
                    //     border-radius: 0;

                    // }

                    // .not-children {
                    //     border-bottom: 1px solid #B6D3FF;
                    //     margin-bottom: 1.2rem;
                    //     border-radius: 6px;
                    // }

                    .tree-level-1,
                    .tree-level-2,
                    .tree-level-3 {
                        border: none;
                        margin-bottom: 0;
                        // border: 1px solid #B6D3FF;
                        // border-radius: 6px;
                        // margin-bottom: 1rem;
                    }

                }
            }

            .ant-tree-node-content-wrapper.ant-tree-node-selected {
                background: none;
                color: #2F67BA
            }

            .ant-tree-node-content-wrapper:hover {
                background: none;
            }

            .ant-tree-treenode-selected {
                background-color: #E1EDFF;
            }

        }
    }

}


.facility-management {
    height: 100%;

    .facility-content__box {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .ant-collapse {
        background-color: white;
        border: none;
        font-size: 1.2rem;
    }

    .panel-location-header {
        font-size: 1.2rem;
    }

    .ant-collapse>.ant-collapse-item {
        border-bottom: none;

        &>.ant-collapse-header {
            padding: 1.2rem 1.6rem;
        }
    }

    .ant-collapse>.ant-collapse-item:last-child,
    .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
        border-radius: 4px;
    }

    .main-view {
        width: 80%;
    }

    .location-list__box {
        width: 18%;
        border-radius: 0.8rem;
        margin-right: 3.2rem;

        .dots-icon {
            cursor: pointer;
        }

        .ant-collapse-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .div-location-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 90%;
            }
        }

        .location-header {
            background: #237bd3;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 1.2rem 2.4rem 1.1rem 2.6rem;
            @include style-text($font-weight-medium, $fs-1, 2.1rem, $color__white);
            margin: 0;

            h3 {
                @include style-text($font-weight-medium, $fs-1, 2.1rem, $color__white);
                margin: 0;

                span {
                    margin-right: 5.5px;
                }
            }
        }

        .location-list-collapse {
            margin: 1.1rem 0.8rem 4.4rem;
            overflow-y: auto;

            .panel-location-header {
                width: 85%;
                display: flex;

                .dropdown__box {
                    justify-self: flex-end;
                    display: inline;
                }
            }

        }

        &>.ant-collapse>.ant-collapse-item>.ant-collapse-content {
            max-height: 76vh;
            overflow: scroll;
        }
    }

    .root-location-header>.ant-collapse-item>.ant-collapse-header {
        color: white;
        background-color: #237BD3;
        border-radius: 4px;
        padding-right: 3rem;

        .panel-location-header {
            font-size: 1.4rem;
        }

    }

    .root-location-header {
        font-weight: 500;
        cursor: pointer;


        .ant-collapse-header {
            border-radius: 4px;
            color: #2F67BA;
        }

        .ant-collapse {
            border-radius: 4px;
            margin: 1.4rem 0;
            border-color: #E1EDFF;

            .ant-collapse-item {
                border-bottom: none;
            }
        }

        .ant-collapse-content {
            border: none;
            border-top: none;
        }

        .ant-collapse-content-active {
            border-radius: 0px 0px 4px 4px;
            border: solid 1px #E1EDFF;

            .ant-collapse-header {
                color: #2F67BA;
                background-color: #E1EDFF;
            }
        }

        .location-item {
            color: #03031DCC;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            padding: 1.1rem 1.6rem 1.1rem 1.1rem;
            font-weight: 400;

            &>span {
                margin-left: 2.1rem;
            }
        }

        .active-location {
            color: #2F67BA;
            background-color: #E1EDFF;
        }

        .ant-collapse-content-box {
            padding: 0rem 1.6rem;

            .ant-collapse-content-box {
                padding: 0rem;
            }
        }

        .ant-collapse-item-active>.ant-collapse-header {
            border-radius: 4px 4px 0px 0px !important;
        }

    }

    .location-name {
        color: #03031D99;
        font-weight: bold;
        font-size: 1.6rem;

        &>p::after {
            content: ":";
        }
    }

    .location-content {
        color: #03031D;
        font-size: 1.6rem;
        font-weight: 400;
    }



}