@use "variable";
@use "mixin";

.device-button-form {
    margin: 4.8rem 0;

    .cancel-button {
        margin-right: 4rem;
    }
}

.render-information {
    p {
        margin-top: 0.7rem;
    }

    .title-information {
        @include style-text($font-weight-medium, $fs-2, 2.4rem, #0A4874);

    }

    .content-information {
        @include style-text($font-weight-normal, $fs-2, 2.4rem, #03031D);
        margin-left: 2.5rem;

    }
}