.footer-component {
  width: 100%;
  margin-top: 3rem;
  background: #ffffff;
  align-items: center;
  text-align: center;
  p {
    margin: 1rem;
    // margin-top: 1.6vh;
    color: #5490eb;
    font-style: italic;
    font-weight: normal;
    font-size: 1.6rem;
  }
}
