@use "variable";
@use "mixin";



.access-manager {
  .card {
    &-container {
      margin-left: 8rem;
    }

    &-grid {
      padding: 0 1rem;

      // display: inline-grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr;
      // grid-column-gap: 3vw;
      .ant-row {
        justify-content: center;
      }
    }

    &-access {
      cursor: pointer;
      position: relative;
      background-color: white;
      box-shadow: 1.6rem 1.6rem 5.2rem rgba(112, 118, 176, 0.06);
      border-radius: 1.6rem;
      height: 33.6rem;
      width: 28.5rem;
      margin-bottom: 1.4rem;
      transition: 0.5s;

      &:hover {
        box-shadow: 1.6rem 1.6rem 5.2rem rgba(112, 118, 176, 0.334);
      }

      &-img {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);

        &.w-72 {
          width: 7.2rem;
        }
      }

      &-title {
        font-size: $fs-5;
        line-height: 3.6rem;
        font-weight: bold;
        position: absolute;
        top: 55%;
        width: 87%;
        transform: translateX(-50%);
        left: 50%;
        text-align: center;
        color: $color__main;
      }
    }
  }
}