@use "variable";

$border-radius: 0.8rem;

.search-in-table {
  margin-top: -7.1rem;
}

.card-main-table {
  background: #ffffff;
  border-radius: 1.2rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-badge-status-text {
    font-size: 1.4rem;
  }

  //CHANGE PAGE SIZE
  .table-function {
    margin-top: -4.3rem;

    .ant-input-number {
      padding: 0.4rem;
      color: #292b2c;
      width: 4.8rem;
      border: 1px solid $color-table-decor;
      border-radius: 4px;
      margin: 0 8px;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input {
      padding: 0;
      color: #0a4874;
      font-weight: 500;
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .pagesize {

    &--show,
    &--entries {
      color: $color-table-decor;
      font-size: 1.2rem;
    }
  }
}



.main-table {
  height: 100%;


  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  //table head
  .ant-table-thead>tr>th {
    color: $color__main;
    background: #e1edff;
    font-weight: 500;
  }

  //padding for all table
  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 1.2rem 0.8rem;
  }

  //for text color
  .ant-table {
    color: #03031dcc;
  }

  //for even row background color
  tr.ant-table-row:nth-child(even) {
    background: #f4f4f4;
  }

  // for border table
  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: $border-radius;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: $border-radius;
  }

  .ant-table-tbody>tr.ant-table-row:last-child>td:first-child {
    border-bottom-left-radius: $border-radius;
  }

  .ant-table-tbody>tr.ant-table-row:last-child>td:last-child {
    border-bottom-right-radius: $border-radius;
  }

  //for detail css
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  //PAGINATION
  .ant-pagination-item {
    border: none;
  }

  li.ant-pagination-item {
    color: $color-table-decor;
  }

  li.ant-pagination-item-active {
    background: #237bd3;
    border-radius: 4px;
    color: white;
  }

  .ant-pagination-disabled .ant-pagination-item-link {
    color: $color-table-decor;
    border-color: transparent;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: transparent;
  }

  .ant-pagination-next .ant-pagination-item-link {
    background-color: #fff;
    color: $color-table-decor;
  }

  .icon-edit {
    color: #2f67ba;
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2.4rem;
  }

  .icon-common {
    color: #2f67ba;
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 2.4rem;
  }

  .ant-pagination-item {
    font-family: $main-font;
  }

  .ant-pagination-options-size-changer.ant-select {
    display: none;
  }

  .ant-table-summary {
    .ant-typography {
      color: #1d519e;
      font-size: 1.4rem;
    }

    .ant-table-cell {
      border-bottom: none;
    }
  }

  .ant-badge-not-a-wrapper {
    width: auto;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  .ant-badge {
    font-size: 1.4rem;
  }

  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding: 0.8rem 0.6rem !important;
    padding-right: 0px !important;
  }
}